import { IconButton, Stack } from "@fluentui/react";
import styles from "./UserChatMessage.module.css";

interface Props {
    message: string;
}

export const UserChatMessage = ({ message }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.message}>
                <Stack horizontal verticalAlign="baseline" horizontalAlign="space-between" className={styles.title}>
                    <IconButton
                        iconProps={{
                            style: {
                                height: "24px"
                            },
                            imageProps: { src: "copy.svg", maximizeFrame: true }
                        }}
                        title="Скопировать"
                        ariaLabel="Скопировать"
                        onClick={() => navigator.clipboard.writeText(message)}
                    />
                    <p>Вы</p>
                </Stack>
                {message}
            </div>
        </div>
    );
};
