import { useMsal } from "@azure/msal-react";
import { Stack, TextField } from "@fluentui/react";
import { Button, Tooltip } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { isLoggedIn, requireAccessControl } from "../../authConfig";

import styles from "./QuestionInput.module.css";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    initQuestion?: string;
    placeholder?: string;
    clearOnSend?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, initQuestion }: Props) => {
    const [question, setQuestion] = useState<string>("");

    useEffect(() => {
        initQuestion && setQuestion(initQuestion);
    }, [initQuestion]);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const { instance } = useMsal();
    const disableRequiredAccessControl = requireAccessControl && !isLoggedIn(instance);
    const sendQuestionDisabled = disabled || !question.trim() || disableRequiredAccessControl;

    if (disableRequiredAccessControl) {
        placeholder = "Please login to continue...";
    }

    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <TextField
                className={styles.questionInputTextArea}
                disabled={disableRequiredAccessControl}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                style={{
                    background: "#d8ecef",
                    fontSize: "20px"
                }}
                onKeyDown={onEnterPress}
            />
            <div className={styles.questionInputButtonsContainer}>
                <Tooltip content="Ask question button" relationship="label">
                    <Button
                        size="large"
                        style={{
                            borderRadius: "12px",
                            boxSizing: "content-box",
                            width: "50px",
                            height: "50px"
                        }}
                        icon={<SendIcon />}
                        disabled={sendQuestionDisabled}
                        onClick={sendQuestion}
                    />
                </Tooltip>
            </div>
        </Stack>
    );
};

const SendIcon = () => {
    return (
        <div style={{}}>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="50" height="50" rx="12" fill="#53B9C9" />
                <path
                    d="M14.6 12C13.1818 12 12 13.1818 12 14.6V14.8364V15.3091L12.7091 19.3273V19.5636C12.8273 20.3909 12.9455 21.1 13.3 21.8091C13.6545 22.2818 14.1273 22.7545 14.6 23.1091C15.1909 23.4636 16.0182 23.4636 16.8455 23.5818H17.0818L25.3545 24.2909C25.7091 24.2909 26.0636 24.6455 26.0636 25C26.0636 25.3545 25.7091 25.7091 25.3545 25.7091L17.0818 26.4182H16.8455C16.0182 26.5364 15.1909 26.5364 14.6 26.8909C14.0091 27.1273 13.5364 27.6 13.3 28.1909C12.9455 28.7818 12.8273 29.4909 12.7091 30.4364V30.6727L12 34.6909V35.1636V35.4C12 36.8182 13.1818 38 14.6 38C14.9545 38 15.4273 37.8818 15.7818 37.7636L36.2273 27.8364C37.2909 27.2455 38 26.1818 38 25C38 23.8182 37.2909 22.7545 36.2273 22.2818L15.7818 12.2364C15.4273 12.1182 15.0727 12 14.6 12Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
