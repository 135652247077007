import { useMemo } from "react";
import { Stack, IconButton, ImageFit } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer.choices[0].message.content;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    <span className={styles.answerTitle}>АКЫЛМАН</span>
                    <IconButton
                        iconProps={{
                            style: {
                                height: "24px"
                            },
                            imageProps: { src: "copy.svg", maximizeFrame: true }
                        }}
                        title="Скопировать"
                        ariaLabel="Скопировать"
                        onClick={() => navigator.clipboard.writeText(messageContent)}
                    />
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal verticalAlign="baseline" wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Источники:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: "10px" }}>
                        <span className={styles.followupQuestionLearnMore}>Спросите еще:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};

export const CopyIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.31 22.7506H5.68C4.5059 22.748 3.38065 22.2804 2.55043 21.4502C1.72022 20.62 1.25264 19.4947 1.25 18.3206V11.6906C1.25 10.5148 1.71641 9.38696 2.54691 8.55458C3.37741 7.72221 4.50417 7.25326 5.68 7.25061H12.31C13.4876 7.25061 14.6169 7.71839 15.4496 8.55105C16.2822 9.38372 16.75 10.513 16.75 11.6906V18.3206C16.7474 19.4964 16.2784 20.6232 15.446 21.4537C14.6136 22.2842 13.4858 22.7506 12.31 22.7506ZM5.68 8.75061C4.902 8.75325 4.15676 9.06417 3.60757 9.61524C3.05837 10.1663 2.75 10.9126 2.75 11.6906V18.3206C2.75 19.0977 3.0587 19.8429 3.60818 20.3924C4.15766 20.9419 4.90292 21.2506 5.68 21.2506H12.31C13.088 21.2506 13.8343 20.9422 14.3854 20.393C14.9364 19.8438 15.2474 19.0986 15.25 18.3206V11.6906C15.25 10.9109 14.9403 10.1631 14.3889 9.61171C13.8375 9.06036 13.0897 8.75061 12.31 8.75061H5.68ZM19.55 16.5706C20.4725 16.3023 21.283 15.7421 21.86 14.9739C22.437 14.2058 22.7493 13.2713 22.75 12.3106V5.68061C22.7474 4.50651 22.2798 3.38126 21.4496 2.55104C20.6194 1.72083 19.4941 1.25325 18.32 1.25061H11.69C10.7622 1.24551 9.8563 1.53251 9.10069 2.07095C8.34508 2.60939 7.77807 3.37196 7.48 4.25061C7.44029 4.3459 7.42068 4.44837 7.4224 4.5516C7.42412 4.65482 7.44713 4.75658 7.49 4.8505C7.53286 4.94442 7.59466 5.02848 7.67151 5.09741C7.74836 5.16635 7.83862 5.21867 7.93663 5.25111C8.03464 5.28355 8.13829 5.29541 8.24109 5.28594C8.3439 5.27647 8.44364 5.24588 8.53407 5.19608C8.62451 5.14628 8.70369 5.07834 8.76665 4.99653C8.82962 4.91471 8.87501 4.82078 8.9 4.72061C9.09561 4.13617 9.47053 3.6283 9.97143 3.26923C10.4723 2.91017 11.0737 2.71819 11.69 2.72061H18.32C19.0971 2.72061 19.8423 3.0293 20.3918 3.57879C20.9413 4.12827 21.25 4.87352 21.25 5.65061V12.2806C21.2398 12.9143 21.0292 13.5286 20.6483 14.0352C20.2675 14.5418 19.736 14.9148 19.13 15.1006C18.939 15.1563 18.778 15.2856 18.6824 15.46C18.5867 15.6344 18.5643 15.8397 18.62 16.0306C18.6757 16.2216 18.805 16.3826 18.9794 16.4782C19.1538 16.5739 19.359 16.5963 19.55 16.5406V16.5706Z"
                fill="#085763"
            />
        </svg>
    );
};
